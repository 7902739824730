
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import Multiselect from '@vueform/multiselect';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';

  export default defineComponent({
    name: 'ire-commercial-areas-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const submitSizeButton = ref<HTMLButtonElement | null>(null);
      const submitPropertyAgeButton = ref<HTMLButtonElement | null>(null);

      const { can } = useAbility();

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('ireAreasCreating'), [
          translate('ire'),
        ]);
      });
      const createdArea = ref<any | null>(null);
      // const createdSubCategory = ref<any | null>(null);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const ireAreaSchema = Yup.object().shape({
        sort: Yup.number()
          .required(() => translate('SORT_IS_REQUIRED_FIELD'))
          .label('sort'),
      });
      await store.dispatch(Actions.GET_ALL_AREAS_LIST, {
        type: 'ire-commercial',
      });

      const areas = computed(() => store.getters.allListAreas);
      const selectedArea = ref<number>(areas.value[0].id);

      // await store.dispatch(Actions.GET_ALL_CCE_CATEGORIES);

      // let sizeDescOptions: any = [];

      // console.log(sizeDescOptions);

      const onSubmitCreate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        // values.areaId = selectedArea.value;
        // values.baseValue =  Number(values.baseValue);
        // values.isSeaView =  values.isSeaView;
        const payload = {
          areaId: selectedArea.value,
          sort: values.sort,
          type: 4,
          customUpdatedAt: values.customUpdatedAt
            ? values.customUpdatedAt
            : undefined,
        };
        const result = await store.dispatch(
          Actions.CREATE_IRE_COMMON_AREA,
          payload
        );
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          createdArea.value = result;

          Swal.fire({
            text: translate('SUCCESSFULLY_CREATED_IRE_AREA'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(async () => {
            router.push({
              name: 'ire-commercial-areas-listing',
            });

            //Deactivate indicator
            submitButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
          });
        }
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        areas,
        onSubmitCreate,
        submitButton,
        translate,
        ireAreaSchema,
        goBack,
        can,
        selectedArea,
        createdArea,
        submitPropertyAgeButton,
        submitSizeButton,
      };
    },
  });
